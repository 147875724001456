@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600&display=swap");

.ag-theme-material {
  /* use theme parameters where possible */
  --ag-header-foreground-color: #1c5026;
  /* --ag-header-cell-moving-background-color: #87bea6;
  --ag-header-cell-hover-background-color: #87bea6; */

  /* Font */
  font-family: "Kanit", sans-serif;
  font-size: 14px;
}

.ag-theme-material .ag-header-row {
  font-size: 14px;
}

.ag-theme-material .ag-root-wrapper {
  /* border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -khtml-border-radius: 15px; */
  padding-right: unset;
  border: 1px solid #d6cfcf;
  height: 100%;
  width: 100%;
  border-collapse: separate;
}

/* .ag-theme-material input[class^="ag-"][type="text"] {
  border: none !important;
  border-bottom: 1px solid #d6cfcf !important;
  border-radius: 0 !important;
} */

.ag-theme-material .ag-header {
  border: unset !important;
  background-color: unset !important;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #1c5026 !important;
}

.ag-chart {
  /* border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -khtml-border-radius: 15px; */
  min-height: calc(60vh) !important;
  border-collapse: separate;
}

.my-chart {
  resize: vertical;
  overflow: auto;
  border: 1px solid #d6cfcf;
  /* border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -khtml-border-radius: 15px; */
  border-collapse: separate;
  width: 100%;
}

.ag-theme-material {
  background-color: #f7faf8;
}

.ag-cell-wrapper.ag-row-group {
  align-items: center;
}

@media screen and (max-device-width: 920px) and (orientation: landscape) {
  .ag-chart {
    min-width: 100%;
    min-height: calc(100vh - 35px) !important;
  }
}
